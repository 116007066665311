import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// guards
import useAuth from '../hooks/useAuth';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import SnowGuard from 'src/guards/SnowGuard';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    // Snow Blowing View
    {
      path: 'snowblowing',
      children: [
        {
          path: ':tenantId',
          element: (
            <SnowGuard>
              <SnowBlowingLanding />
            </SnowGuard>
          ),
        },
        {
          path: ':tenantId/:clientId/:token',
          element: (
            <SnowGuard>
              <PaymentCompleteSnowBlowing />
            </SnowGuard>
          ),
        },
      ],
    },
    // Estiamte View
    {
      path: 'estimate',
      children: [
        {
          path: ':tenantId/:clientId/:estimateId',
          element: (
            <GuestGuard>
              <ClientViewLanding view="Estimate" vertifyWording="View estimate details" />
            </GuestGuard>
          ),
        },
      ],
    },
    // Client View
    {
      path: 'clientView',
      children: [
        {
          path: ':tenantId/:clientId',
          element: (
            <GuestGuard>
              <ClientViewLanding view="Client" vertifyWording="View invoice and more info" />
            </GuestGuard>
          ),
        },
        {
          path: ':tenantId/:clientId/:token',
          element: (
            <GuestGuard>
              <PaymentComplete />
            </GuestGuard>
          ),
        },
        {
          path: ':tenantId',
          element: (
            <GuestGuard>
              <SnowBlowingLanding />
            </GuestGuard>
          ),
        },
      ],
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'winter', element: <GeneralWinterView /> },
        { path: 'SnowEvent', element: <GeneralWinterCommandCenterView /> },
        {
          path: 'SnowEvent',
          children: [{ path: ':id', element: <SnowEventView /> }],
        },

        {
          path: 'clients',
          children: [
            { element: <Navigate to="/dashboard/clients" replace />, index: true },
            { path: 'new', element: <NewClient /> },
            { path: 'list', element: <ClientList /> },
            { path: 'view/:id', element: <ClientProfile /> },
          ],
        },

        {
          path: 'estimates',
          children: [
            { element: <Navigate to="/dashboard/estimates" replace />, index: true },
            { path: 'new', element: <NewEstimate /> },
            { path: 'list', element: <EstimateList /> },
            { path: 'view/:id', element: <ClientEstimate /> },
          ],
        },
        {
          path: 'service',
          children: [
            { element: <Navigate to="/dashboard/service" replace />, index: true },
            { path: 'create', element: <NewServiceDetails /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },

            { path: ':id', element: <InvoiceDetails /> },
            // { path: ':id/edit', element: <InvoiceEdit /> },
            // { path: 'new', element: <InvoiceCreate /> },
          ],
        },

        {
          path: 'account',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <AccountProfile /> },
            { path: 'initialzingStripe', element: <FullScreenLoading /> },
            { path: 'success', element: <StripeSuccess /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
      ],
    },
    {
      path: '/',
      children: [
        {
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
          index: true,
        },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

//Snow Blowing:
const SnowBlowingLanding = Loadable(lazy(() => import('../pages/snowBlowing/SnowBlowingLanding')));
const PaymentCompleteSnowBlowing = Loadable(
  lazy(() => import('../pages/snowBlowing/PaymentComplete'))
);

// Client View
const ClientViewLanding = Loadable(lazy(() => import('../pages/clientView/ClientViewLanding')));
const PaymentComplete = Loadable(lazy(() => import('../pages/clientView/PaymentComplete')));

// Winter View
const GeneralWinterView = Loadable(lazy(() => import('../pages/winterView/GeneralWinterView')));
const GeneralWinterCommandCenterView = Loadable(
  lazy(() => import('../pages/winterView/CommandCenterView/GeneralWinterCommandCenterView'))
);
const SnowEventView = Loadable(
  lazy(() => import('../pages/winterView/CommandCenterView/SnowEventView'))
);

// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const ClientList = Loadable(lazy(() => import('../pages/dashboard/clients/ClientList')));
const ClientProfile = Loadable(lazy(() => import('../pages/dashboard/clients/ClientProfile')));
const NewEstimate = Loadable(lazy(() => import('../pages/dashboard/NewEstimate')));
const ClientEstimate = Loadable(lazy(() => import('../pages/dashboard/estimates/ClientEstimate')));
const EstimateList = Loadable(lazy(() => import('../pages/dashboard/estimates/EstimateList')));
const NewClient = Loadable(lazy(() => import('../pages/dashboard/clients/NewClient')));
const AccountProfile = Loadable(lazy(() => import('../pages/dashboard/account/AccountProfile')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const FullScreenLoading = Loadable(lazy(() => import('../pages/FullScreenLoading')));
const StripeSuccess = Loadable(lazy(() => import('../pages/StripeSuccess')));
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const NewServiceDetails = Loadable(
  lazy(() => import('../pages/dashboard/clients/NewServiceBackDate'))
);
